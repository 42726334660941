import React,{ PureComponent } from "react";
import * as myConstClass from '../../../constant.js';
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import './header.scss';
import { CSVLink } from "react-csv";
import {
  Card, Container,Row,
  CardBody,
  CardHeader,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

import { MoreVertical, RefreshCw } from "react-feather";
import CsvDownloader from 'react-csv-downloader';
const Ava  = `https://alpha.workzoneplus.com/img/profile.png`;
class LineChart extends PureComponent {
  constructor(props) {
    super(props);
   
    this.state = {
      user_id:0,
      checkin: [],
      absent: [],
      posts: [],
      emp: [],
      formData:'',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
    this.headers = [
      { displayName: "Employee", id: "employee" },
      { displayName: 'Attendance Date',id: "attendance_date"},  
      { displayName: "Department", id: "department" },
      { displayName: "Organization", id: "organization" },

    ];
  }


  componentDidMount() {
    var admin_id = localStorage.getItem('uname'); 
    fetch(myConstClass.BASE_URL+'attendance/getAbsentEmp/'+admin_id)
    .then(response => response.json())
    .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))   
        
    fetch(myConstClass.BASE_URL+'attendance/getAllAbsentEmp/'+admin_id)
    .then(response => response.json())
    .then(response =>{this.setState({absent: response})}).catch(err => console.log(err))  
  }

  render() {
    const {posts,absent} = this.state;
    const csvData = this.state.absent.rows;
    const headers = this.headers;
    let fileName = 'Absent_Employees_'+new Date().getTime()+'.csv';
    let tableData;
    if(posts == ''){
      tableData = <h4 className="heading">Data Not Available....!</h4>;
    } else {
     tableData = posts.map((posts) =>
          <div class='col-md-12'>
               <div class='col-md-1'>
                  <img className="topbar__avatar-img" src={Ava} alt="avatar" /> </div>
                    <div class='col-md-11' style={{width:'306px', top: '-34px', left: '54px'}}>
                    {posts.employee} <br/>   {posts.department+' / '+posts.organization}   
                </div>
            </div>
    );
    }
    return (

        <Container>
          <Card>
          <CardTitle tag="h3" className="mb-0">
           <h4 className="heading">  Today's Absent Employee </h4>
           <div class="col-md-1 csv-btn" >
           {/* <CSVLink data={csvData} headers={headers}>
  Download me
</CSVLink>; */}
          {/* <CsvDownloader
          className="csv"
          style={{float:'right',backgroundColor:"#4B0082"}}
          filename={fileName}
          separator=","
          columns={headers}
          datas={csvData}
          text="Export" /> */}
          </div>
          </CardTitle><br/>
          {/* <CardBody className="py-3">
  
             <Row>
              {tableData}
             </Row>
             </CardBody> */}
              <CardBody className="py-3">
          <div class="ScrollStyle" style={{float:'left',width:'400px',overflow: 'auto',height:'600px'}}>
             <Row>
              {tableData}
             </Row></div>
             </CardBody>
           </Card>
         </Container>
    );
  }

}



// const LineChart = ({ theme }) => {
//   const data = {
//     labels: ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
//     datasets: [
//       {
//         label: "Orders",
//         fill: true,
//         backgroundColor: theme.primary,
//         borderColor: theme.primary,
//         borderWidth: 2,
//         data: [3, 2, 3, 5, 6, 5, 4, 6, 9, 10, 8, 9]
//       },
//       {
//         label: "Sales ($)",
//         fill: true,
//         backgroundColor: "rgba(0, 0, 0, 0.05)",
//         borderColor: "rgba(0, 0, 0, 0.05)",
//         borderWidth: 2,
//         data: [5, 4, 10, 15, 16, 12, 10, 13, 20, 22, 18, 20]
//       }
//     ]
//   };

//   const options = {
//     maintainAspectRatio: false,
//     legend: {
//       display: false
//     },
//     tooltips: {
//       intersect: false
//     },
//     hover: {
//       intersect: true
//     },
//     plugins: {
//       filler: {
//         propagate: false
//       }
//     },
//     elements: {
//       point:{
//         radius: 0
//       }
//     },
//     scales: {
//       xAxes: [{
//         reverse: true,
//         gridLines: {
//           color: "rgba(0,0,0,0.0)"
//         }
//       }],
//       yAxes: [{
//         ticks: {
//           stepSize: 5
//         },
//         display: true,
//         gridLines: {
//           color: "rgba(0,0,0,0)",
//           fontColor: "#fff"
//         }
//       }]
//     }
//   };

//   return (
//     <Card className="flex-fill w-100">
//       <CardHeader>
//         <div className="card-actions float-right">
//           <span className="cursor-pointer mr-1">
//             <RefreshCw />
//           </span>{" "}
//           <UncontrolledDropdown className="d-inline-block">
//             <DropdownToggle tag="a">
//               <MoreVertical />
//             </DropdownToggle>
//             <DropdownMenu right>
//               <DropdownItem>Action</DropdownItem>
//               <DropdownItem>Another Action</DropdownItem>
//               <DropdownItem>Something else here</DropdownItem>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         </div>
//         <CardTitle tag="h5" className="mb-0">
//           Recent Movement
//         </CardTitle>
//       </CardHeader>
//       <CardBody className="py-3">
//         <div className="chart chart-sm">
//           <Line data={data} options={options} />
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

export default connect(store => ({
  theme: store.theme.currentTheme
}))(LineChart);
// export default LineChart;