import React ,{ PureComponent } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { Redirect } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";
import { toastr } from "react-redux-toastr";
import * as myConstClass from '../../constant.js';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;



class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      user_id:'0',
      units:'',
      formData:{
        title: '',
        quantity: '',
        weight: '',
        unit: '',
        sequence:'',
      },
      submitted: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
   
    this.options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
  //this.handleSubmit = this.handleSubmit.bind(this);
}

onChangeHandler=event=>{
  console.log(event.target.files[0]);
  this.setState({
    selectedFile: event.target.files[0],
    loaded: 0,
  })
}

routeChange() {
  // this.props.history.push('https://www.workzoneplus.com/');
  window.location.href ='https://www.workzoneplus.com/';
}
handleSubmit = (e) => {
  e.preventDefault();
  const options = { 
    timeOut: parseInt(this.state.timeOut),
    showCloseButton: this.state.showCloseButton,
    progressBar: this.state.progressBar,
    position: this.state.position
  };

  this.setState({ submitted: true }, () => {  
    const data = new FormData() 
    data.append('file', this.state.selectedFile);
    data.append('formdata',JSON.stringify($('#pform').serializeArray()));
    setTimeout(() => this.setState({ submitted: false }), 5000);
          fetch(myConstClass.BASE_URL+`user/add_request/`, {
          method: 'POST',
          body:data,
          }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toastr.success( 'Success',"Request Added Successfully..!",
                  options
                );       
               
                this.routeChange();       
               // toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                // this.props.history.push('/pages/signup/message');
     
              } else if(response == 3){ 
                toastr.error(
                   'Error',
                   "Please Upload Personnal Document..!",
                   this.options
                 )
             } else{
               // toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });

}

render() {
  const { formData, submitted,branch,codes } = this.state;
  return (
  
    <div class = "wrapper">
    <Container>
      <div className="text-center mt-4">
      <h1 className="h2">Sign Up Form</h1>
      <p className="lead">
      </p>
    </div>
    <Card>
      
  <div className="text-center mt-4">
      <img class="dashboar-plus-logo" src={logo}  style={{width: '40px',height:'39px'}}/>  <img class="dashboar-plus-logo" src={zone_logo}  style={{width: '197px',height:'30px'}}/>
  </div>


    <CardBody>
    <AvForm id="pform"  onSubmit={this.handleSubmit}>
        {/* <FormGroup>
              <Label>Name</Label>
              <Input bsSize="lg" type="text" name="name"  placeholder="Enter your name"  />
        </FormGroup> */}
        <AvGroup>
            <Label for="example">Name</Label>
            <AvInput name="name" id="example" placeholder="Enter Name" required value={formData ? formData.name:''}/>
            <AvFeedback>Enter Name!</AvFeedback>
          </AvGroup>
        {/* <FormGroup>
              <Label>Organization</Label>
              <Input bsSize="lg" type="text" name="organization"  placeholder="Enter your Organization Name"  />
        </FormGroup> */}
        <AvGroup>
            <Label for="example">Organization</Label>
            <AvInput name="organization" id="example" placeholder="Enter Organization" required value={formData ? formData.organization:''}/>
            <AvFeedback>Enter Organization!</AvFeedback>
          </AvGroup>
          <AvGroup>
            <Label for="example">Mobile No</Label>
            <AvInput name="mobile_no" id="example" placeholder="Enter Mobile No" required value={formData ? formData.mobile_no:''}/>
            <AvFeedback>Enter Mobile No !</AvFeedback>
          </AvGroup>
        {/* <FormGroup>
              <Label>Mobile</Label>
              <Input bsSize="lg" type="text" name="mobile_no"  placeholder="Enter your Mobile"  />
        </FormGroup> */}
        {/* <FormGroup>
          <Label>Email address</Label>
          <Input type="email" name="email" placeholder="Email" />
        </FormGroup> */}
        <AvGroup>
            <Label for="example">Email address</Label>
            <AvInput type ="email" name="email" id="example" placeholder="Enter Email" required value={formData ? formData.email:''}/>
            <AvFeedback>Enter Email !</AvFeedback>
          </AvGroup>
        {/* <FormGroup>
              <Label>Position</Label>
              <Input bsSize="lg" type="text" name="position"  placeholder="Enter your Position"  />
        </FormGroup> */}
        <AvGroup>
            <Label for="example">Position</Label>
            <AvInput name="position" id="example" placeholder="Enter Position" required value={formData ? formData.position:''}/>
            <AvFeedback>Enter Position !</AvFeedback>
          </AvGroup>
        <FormGroup>
              <Label>Website</Label>
              <Input bsSize="lg" type="text" name="website"  placeholder="Enter your Website"  />
        </FormGroup>
        <FormGroup>
              <Label>City</Label>
              <Input bsSize="lg" type="text" name="city"  placeholder="Enter your City"  />
        </FormGroup>
        <FormGroup>
              <Label>Country</Label>
              <Input bsSize="lg" type="text" name="country"  placeholder="Enter your Country"  />
        </FormGroup>
        <FormGroup>
          <Label>Upload Personnal ID Proof</Label>
          <Input type="file" name="personal_doc"  onChange={this.onChangeHandler}/>
          <FormText color="muted"></FormText>
        </FormGroup>
        <FormGroup>
        <h4><b>Please Note *</b></h4><br/>
        <h5>1) Organisation must be a registered Identity in the country of issue.</h5>
        <h5>2) Personal ID Document is need to verify system operator.</h5>
        <h5>3) Subject to terms and conditions.</h5>
        </FormGroup>
        <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
                </AvForm>
      </CardBody>
      </Card>
  </Container>
  // </div>
  )
 }
}
export default SignUp;
