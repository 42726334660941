import async from "../components/Async";
import React, { Component }  from 'react';
import {
  faBook,
  faChartPie,
  faCheckSquare,
  faDesktop,
  faFile,
  faFlask,
  faHeart,
  faHome,
  faMapMarkerAlt,
  faTable,
  faUser,
  faUserCircle,
  faClock,
  faIdCard,
  faSignInAlt,
  faMicrophone,
  faCalendar,
  faImages
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import * as Icon from "react-feather";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import SidebarLeft from "../pages/layouts/SidebarLeft";
import SidebarRight from "../pages/layouts/SidebarRight";
import ThemeModern from "../pages/layouts/ThemeModern";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeDark from "../pages/layouts/ThemeDark";
import ThemeLight from "../pages/layouts/ThemeLight";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Notifications from "../pages/ui-elements/Notifications";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";



// Pages
const Settings = async(() => import("../pages/pages/Settings"));
const Clients = async(() => import("../pages/pages/Clients"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Tasks = async(() => import("../pages/pages/Tasks"));

// Documentation
const Documentation = async(() => import("../pages/docs/Documentation"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicElements = async(() => import("../pages/forms/BasicElements"));
const AdvancedElements = async(() => import("../pages/forms/AdvancedElements"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const AdvancedTables = async(() => import("../pages/tables/Advanced"));
//Measures
const MListing = async(() => import("../pages/measures/Listing"));
const MacroListing = async(() => import("../pages/measures/MacroListing"));
const MicroListing = async(() => import("../pages/measures/MicroListing"));
const Add = async(() => import("../pages/measures/Validation"));
const MacroAdd = async(() => import("../pages/measures/MacroValidation"));
const MicroAdd = async(() => import("../pages/measures/MicroValidation"));

//Ingredients
const IListing = async(() => import("../pages/ingredients/Listing"));
const AddI = async(() => import("../pages/ingredients/Validation"));
// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const IonIcons = async(() => import("../pages/icons/IonIcons"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));


//Signup Request
const SListing = async(() => import("../pages/signup/Listing"));
const SView = async(() => import("../pages/signup/view"));
const SMsg = async(() => import("../pages/signup/message"));

//HR Admin
const HRListing = async(() => import("../pages/hradmin/Listing"));
const HRAdd = async(() => import("../pages/hradmin/Validation"));

//for employee
const EListing = async(() => import("../pages/employee/Listing"));
const EAdd = async(() => import("../pages/employee/Validation"));

//for organization
const OListing = async(() => import("../pages/organization/Listing"));
const OAdd = async(() => import("../pages/organization/Validation"));

//for Attendance
const AListing = async(() => import("../pages/attendance/Listing"));
const AAdd = async(() => import("../pages/attendance/Validation"));
const AView = async(() => import("../pages/attendance/view"));

//for Leaves
const LListing = async(() => import("../pages/leaves/Listing"));
const LAdd = async(() => import("../pages/leaves/Validation"));

//for Announcements
const AnListing = async(() => import("../pages/announcements/Listing"));
const AnAdd = async(() => import("../pages/announcements/Validation"));


//for Prormotions
const PListing = async(() => import("../pages/promotions/Listing"));
const PAdd = async(() => import("../pages/promotions/Validation"));

//for Prormotions
const SLListing = async(() => import("../pages/logs/Listing"));


var admin_id= localStorage.getItem('uname');

// Routes
const landingRoutes = {
  path: "/landing",
  name: "Landing Page",
  component: Landing,
  children: null
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  children: null
};

const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: faDesktop,
  children: [
    {
      path: "/layouts/sidebar-left",
      name: "Left Sidebar",
      component: SidebarLeft
    },
    {
      path: "/layouts/sidebar-right",
      name: "Right Sidebar",
      component: SidebarRight
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-dark",
      name: "Dark Theme",
      component: ThemeDark
    },
    {
      path: "/layouts/theme-light",
      name: "Light Theme",
      component: ThemeLight
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "User Interface",
  header: "Elements",
  icon: faFlask,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/general",
      name: "General",
      component: General
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: faChartPie,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: faCheckSquare,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-elements",
      name: "Basic Elements",
      component: BasicElements
    },
    {
      path: "/forms/advanced-elements",
      name: "Advanced Elements",
      component: AdvancedElements
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/forms/validation",
      name: "Validation",
      component: Validation
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: faTable,
  children: [
    {
      path: "/tables/bootstrap",
      name: "Bootstrap",
      component: BootstrapTables
    },
    {
      path: "/tables/advanced-tables",
      name: "Advanced",
      component: AdvancedTables
    }
  ]
};

//for signup req and manage employee modules according to  admin 
//if super admin
// const signupRoutes = {
//   path: "/signup/listing",
//   name: "Signup Requests",
//   icon: faIdCard,
//   component: SListing,
//   children: null
// };

const signupRoutes = (admin_id !=0 ) ? {
  path: "/dashboard",
  name: "",
  icon:'',
  component: Default,
  children: null
}:{
  path: "/signup/listing",
  name: "Signup Requests",
  icon:faIdCard,
  component: SListing,
  children: null
};


const logs = (admin_id !=0 ) ? {
  path: "/dashboard",
  name: "",
  icon:'',
  component: Default,
  children: null
}:{
  path: "/logs/listing",
  name: "System Logs",
  icon:faIdCard,
  component: SLListing,
  children: null
};

//if hr admin
const empRoutes = {
  path: "/employee/listing",
  name: "Manage Employee",
  icon: faUser,
  component:  EListing,
  children: null
  };

  const empRoutes1 = {
    path: "/employee/listing",
    name: "Manage Employee",
    icon: faUser,
    component:  EListing,
    children: [
      {
        path: "/employee/add/:id",
       // name: "Add",
        component: EAdd
      },
    ]
    };
  
    const orgRoutes = {
      path: "/organization/listing",
      name: "Manage Organization",
      icon: faTable,
      component:  OListing,
      children: null
      };

      const orgRoutes1 = {
        path: "/organization/listing",
        name: "Manage Organization",
        icon: faTable,
        component:  OListing,
        children: [
          {
            path: "/organization/add/:id",
           // name: "Add",
            component: OAdd
          },
        ]
        };


        const attendanceRoutes = {
          path: "/attendance/listing",
          name: "Manage Attendance",
          icon: faClock,
          component:  AListing,
          children: null
          };
    
          const attendanceRoutes1 = {
            path: "/attendance/listing",
            name: "Manage Attendance",
            icon: faClock,
            component:  AListing,
            children: [
              {
                path: "/attendance/add/:id",
               // name: "Add",
                component: AAdd
              },
              {
                path: "/attendance/view/:id",
               // name: "Add",
                component: AView
              },
            ]
            };


//for laeaves
const leaveRoutes = {
  path: "/leaves/listing",
  name: "Manage Leaves",
  icon: faCalendar,
  component:  LListing,
  children: null
  };

  const leaveRoutes1 = {
    path: "/leaves/listing",
    name: "Manage Leaves",
    icon: faCalendar,
    component:  LListing,
    children: [
      {
        path: "/leaves/add/:id",
       // name: "Add",
        component: LAdd
      },
    ]
    };


    //for Announcements
const announcementRoutes = {
  path: "/announcements/listing",
  name: "Manage Announcements",
  icon: faMicrophone,
  component:  AnListing,
  children: null
  };

  const announcementRoutes1 = {
    path: "/announcements/listing",
    name: "Manage Announcements",
    icon: faMicrophone,
    component:  AnListing,
    children: [
      {
        path: "/announcements/add/:id",
       // name: "Add",
        component: AnAdd
      },
    ]
    };

    // const promotionRoutes = (admin_id !=0 ) ? {
    //   path: "/",
    //   name: "",
    //   icon: '',
    //   component:  '',
    //   children: null
    //   } : {
    //     path: "/promotions/listing",
    //     name: "Manage Promotions",
    //     icon: faImages,
    //     component:  PListing,
    //     children: null
    //     };

// let promotionRoutes;
// if(admin_id == 0){
// //for Promotions
//  promotionRoutes = {
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon: faImages,
//   component:  PListing,
//   children: null
//   };
// } else {
//   //for Promotions
//  promotionRoutes = {
//   path: "/",
//   name: "",
//   icon: '',
//   component:  '',
//   children: null
//   };
// }

const promotionRoutes = (admin_id !=0 ) ? {
  path: "/dashboard",
  name: "",
  icon:'',
  component: Default,
  children: null
}:{
  path: "/promotions/listing",
  name: "Manage Promotions",
  icon:faImages,
  component: PListing,
  children: null
};

  const promotionRoutes1 = {
    path: "/promotions/listing",
    name: "Manage Promotions",
    icon: faImages,
    component:  PListing,
    children: [
      {
        path: "/promotions/add/:id",
       // name: "Add",
        component: PAdd
      },
    ]
    };
    
//  const viewRoutes1;

const viewRoutes1=   {
  path: "/signup",
  name: "SignUp Requests",
  icon: faIdCard,
  children: [
    {
      path: "/signup/view/:id",
     // name: "Add",
      component: SView
    },
    {
      path: "/signup/message",
     // name: "Add",
      component: SMsg
    },
  ]
};

//   const viewRoutes1 = {
//   path: "/signup",
//   name: "SignUp Requests",
//   icon: faTable,
//   children: [
//     {
//       path: "/signup/view/:id",
//      // name: "Add",
//       component: SView
//     },
//     {
//       path: "/signup/message",
//      // name: "Add",
//       component: SMsg
//     },
//   ]
// } 

// let hradminRoutes;
// if(admin_id == 0){
//   //for Promotions
//   hradminRoutes = {
//     path: "/hradmin/listing",
//     name: "Manage HR Admin",
//     icon:faUserCircle,
//     component: HRListing,
//     children: null
//     };
//   } else {
//     //for Promotions
//     hradminRoutes = {
//     path: "/",
//     name: "",
//     icon: '',
//     component:  '',
//     children: null
//     };
//   }
const hradminRoutes = (admin_id !=0 ) ? {
  path: "/dashboard",
  name: "",
  icon:'',
  component: Default,
  children: null
}:{
  path: "/hradmin/listing",
  name: "Manage HR Admin",
  icon:faUserCircle,
  component: HRListing,
  children: null
};


const hradminRoutes1 = { 
  path: "/hradmin",
  name: "Manage HR Admin",
  icon: faUserCircle,
  children: [
    {
      path: "/hradmin/add/:id",
     // name: "Add",
      component: HRAdd
    }, 
  ]
}

const measureRoutes1 = {
  path: "/measures",
  name: "Masters",
  icon: faTable,
  children: [
    {
      path: "/measures/add/:id",
     // name: "Add",
      component: Add
    },
    {
      path: "/measures/micro_add/:id",
     // name: "Add",
      component: MicroAdd
    }, 
    {
      path: "/measures/macro_add/:id",
     // name: "Add",
      component: MacroAdd
    }
  ]
}

const measureRoutes = {
  path: "/measures",
  name: "Manage Masters",
  icon: faTable,
  children: [
    {
      path: "/measures/listing",
      name: "Measures",
      component: MListing
    },
    {
      path: "/measures/macro_listing",
      name: "Macronutrients",
      component: MacroListing
    },
    {
      path: "/measures/micro_listing",
      name: "Micronutrients",
      component: MicroListing
    }
  ]
};





// const measureRoutes1 = {
//   path: "/signup",
//   name: "Signup Requests",
//   icon: faTable,
//   children: [
//     {
//       path: "/signup/add/:id",
//      // name: "Add",
//       component: SAdd
//     },
//   ]
// }

const ingredientRoutes = {
  path: "/ingredients",
  name: "Manage Ingredients",
  icon: faTable,
  children: [
    {
      path: "/ingredients/listing",
      name: "Ingredients",
      component: IListing
    }
  ]
};

const ingredientRoutes1 = {
  path: "/ingredients",
  name: "Masters",
  icon: faTable,
  children: [
    {
      path: "/ingredients/add/:id",
     // name: "Add",
      component: AddI
    },
    {
      path: "/measures/micro_add/:id",
     // name: "Add",
      component: MicroAdd
    }, 
    {
      path: "/measures/macro_add/:id",
     // name: "Add",
      component: MacroAdd
    }
  ]
}

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: faHeart,
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/ion-icons",
      name: "Ion Icons",
      component: IonIcons
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: faCalendarAlt,
  component: Calendar,
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: faMapMarkerAlt,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: faFile,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/signup",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const documentationRoutes = {
  path: "/documentation",
  name: "Getting Started",
  header: "Extras",
  icon: faBook,
  component: Documentation,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  layoutRoutes,
  uiRoutes,
  chartRoutes,
  formRoutes,
  tableRoutes,measureRoutes1,
  measureRoutes,ingredientRoutes,ingredientRoutes1,
  iconRoutes,
  calendarRoutes,
  mapRoutes,
  pageRoutes,
  documentationRoutes,
  privateRoutes,
  signupRoutes,viewRoutes1,hradminRoutes1,hradminRoutes,empRoutes,empRoutes1,orgRoutes,orgRoutes1,leaveRoutes,leaveRoutes1,announcementRoutes,announcementRoutes1,
  attendanceRoutes,attendanceRoutes1,promotionRoutes,promotionRoutes1,logs

];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const measure = [measureRoutes];







// All routes
export default [
  
  dashboardRoutes,
 // pageRoutes,
  //authRoutes,
  //uiRoutes,
 // chartRoutes,
  //formRoutes,
 // mRoutes,
   attendanceRoutes,
   empRoutes,
   orgRoutes,  
   leaveRoutes,
   announcementRoutes,
   hradminRoutes,
   promotionRoutes,
   signupRoutes,
   logs,
  //iconRoutes,
  //calendarRoutes,
  //mapRoutes,
  //documentationRoutes,
  //layoutRoutes
];

  