import React from "react";
import { Container, Row, Col } from "reactstrap";

import BarChart from "./BarChart";
import Calendar from "./Calendar";
import LineChart from "./LineChart";

import PieChart from "./PieChart";
import Statistics from "./Statistics";
import Table from "./Table";
import WorldMap from "./WorldMap";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import HeaderSubtitle from "../../../components/HeaderSubtitle";

const Default = () => (
  
  <Container fluid>
    <Header>
      <HeaderTitle>Welcome back,{localStorage.getItem('utype')} !</HeaderTitle>
      <HeaderSubtitle>

      </HeaderSubtitle>
    </Header>

    <Row>
   
      <Col xl="12" className="d-flex col-xxl-5">
        <Statistics />
      </Col>
    </Row>
    <Row>
    <Col xl="6" className="d-flex col-xxl-7">
        <LineChart />
      </Col>
     
      <Col md="6" className="col-xxl-3  d-flex order-2 order-xxl-3">
        {/* <PieChart /> */}
        <Calendar />
      </Col>
    </Row>
    <Row>
      <Col lg="8" className="col-xxl-9 d-flex">
        {/* <Table /> */}
      </Col>
      <Col lg="4" className="col-xxl-3  d-flex">
        {/* <BarChart /> */}
      </Col>
    </Row>
  </Container>
);

export default Default;
