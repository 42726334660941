import React , { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery'; 
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import avatar from "../../assets/img/avatars/avatar.jpg";
// const image = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `http://localhost/workzone/backend/img/clock.png`;
// const image3 = `http://localhost/workzone/backend/img/logo2.png`;

const image  = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
const image2 = `https://alpha.workzoneplus.com/backend/img/clock.png`;
const image3 = `https://alpha.workzoneplus.com/img/logo2.png`;



// const {logout} = props.location.state;
class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    };
    this.login = this.login.bind(this);
  }



  login(e) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };

    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`login/verifyLogin/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
      
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     }else if(response == 3){
       toastr.error(
           'Error',
           "Please enter password ..!",
           this.options
         )
     } else if(response == 4){
      toastr.error(
          'Error',
          "Incorrect Credentials ...!",
          this.options
        )
    }else if(response !== false){
      var sarray = response.split('-');
      // localStorage.setItem('admin_id', sarray['3']);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);

      this.setState(() => ({
        toDashboard: true
      }))   
    } else {
     toastr.error(
       'Error',
       "Login Unsuccessful ..!",
       this.options
     )
   }
    })
    .catch();

    
  }

  render() {
    if (this.state.toDashboard === true) {
      window.location.href = '/dashboard'; 
      return <Redirect to='/dashboard' />
    }
return(
  <React.Fragment>
    <div className="text-center mt-4">   
    </div>
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
          <img src={image2} style={{'width':'40px','height':'40px'}}></img>
          <img src={image} style={{'width':'200px','height':'27px;'}}></img>
            {/* <img
              src={avatar}
              alt="Linda Miller"
              className="img-fluid rounded-circle"
              width="132"
              height="132"
            /> */}
          </div>
          <Form className="form" id="pform">
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="Enter your password"
              />
              {/* <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small> */}
            </FormGroup>
            <div>
              {/* <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              /> */}
            </div><br/>
            <div className="text-center mt-3">
              <Link to="/dashboard">
                <Button color="primary" size="lg" onClick={this.login}>
                  Sign in
                </Button>
              </Link>
            </div> <br/>
            <center><img src={image3} style={{'width':'40%'}}></img><br/>   
       <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center>
     
          </Form>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
)
  }
}

export default LogInForm;
